function gaTrack () {
  const trackLinks = document.getElementsByClassName('js-track')

  for (let index = 0; index < trackLinks.length; ++index) {
    let elem = trackLinks[index]
    const event = elem.dataset.trackingEvent ? elem.dataset.trackingEvent : 'click'
    elem.addEventListener(event, function (e) {
      sendEvent(e.currentTarget)
    })
  }

  const $trackView = document.querySelector('.js-track-view')
  if ($trackView) {
    sendEvent($trackView, $trackView.dataset.trackingNonInteraction)
  }
}

function sendEvent ($el, nonInteraction = false) {
  if (window.gtag) {
    if (nonInteraction) {
      window.gtag(
        'event',
        $el.dataset.trackingAction,
        {
          'event_category': $el.dataset.trackingCategory,
          'event_label': $el.dataset.trackingLabel,
          'non_interaction': nonInteraction
        }
      )
    } else {
      window.gtag(
        'event',
        $el.dataset.trackingAction,
        {
          'event_category': $el.dataset.trackingCategory,
          'event_label': $el.dataset.trackingLabel
        }
      )
    }
  }
}

gaTrack()
